import {useState} from 'react';
import {useDebounce} from 'react-use';

export default function useStateDebounced(initialValue, delay = 150) {
  const [currentValue, setCurrentValue] = useState(initialValue);
  const [debouncedValue, setDebouncedValue] = useState(initialValue);

  useDebounce(() => setDebouncedValue(currentValue), delay, [currentValue]);

  return [debouncedValue, setCurrentValue, currentValue];
}