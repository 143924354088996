import {format as formatDate, parseISO as parseISODate} from 'date-fns';

export const formattedDate = (value, format, fallbackValue) => value ? formatDate(parseISODate(value), format) : fallbackValue;

export const formatTime = (value, fallbackValue) => formattedDate(value, 'hh:mm aa', fallbackValue);

export const formatTimeSeconds = (value, fallbackValue) => formattedDate(value, 'hh:mm:ss aa', fallbackValue);

export const formatDay = (value, fallbackValue) => formattedDate(value, 'eeee', fallbackValue);

export const formatShortDate = (value, fallbackValue) => formattedDate(value, 'dd MMM', fallbackValue);

export const formatShortDatetime = (value, fallbackValue) => formattedDate(value, 'hh:mm aa, dd MMM', fallbackValue);

export const formatLongDate = (value, fallbackValue) => formattedDate(value, 'dd MMM yyyy', fallbackValue);

export const formatLongDatetime = (value, fallbackValue) => formattedDate(value, 'hh:mm aa, dd MMMM yyyy', fallbackValue);

export const formatNumber = (value, fallbackValue, formatterOptions = {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
  style: 'decimal',
}) => {
  const numberFormatter = new Intl.NumberFormat(undefined, formatterOptions);
  return (value === undefined || value === null) ? fallbackValue : numberFormatter.format(value);
};

export const formatNull = (string) => string || '';

export const formatPlural = (value, entityName, pluralSuffix='s') => {
  return `${value} ${entityName}${formatNull(value > 1 && pluralSuffix)}`
}

export const formatRange = (min, max, {separator=" - ", greaterSymbol="> ", lessSymbol="< ", usePlus, unit}={}) => {
  return `${formatNull(!min ? lessSymbol: !max && greaterSymbol)}${formatNull(min)}${formatNull(!max && usePlus && '+')}${formatNull(min && max && separator)}${formatNull(max)}${formatNull(unit)}`
}

export const formatAlphanumericOnly = (value, fallbackValue) => value ? value.replaceAll(/[^a-zA-Z0-9]/g, '') : fallbackValue;

export const capitaliseWords = (stringValue, fallbackValue) => {
  return stringValue ? stringValue.split(' ').map(word => word[0].toUpperCase() + word.slice(1)).join(' ') || fallbackValue : fallbackValue;
};