import {usePage} from '@inertiajs/inertia-react';

const useUser = () => {
  const {application: {user}} = usePage().props;

  return {
    ...user,
    isRole: (...roles) => roles?.includes(user.role?.value),
  };

};

export default useUser