import {usePage} from '@inertiajs/inertia-react';
import HeaderBG from 'Images/galvanised_header_bg.jpg';
import ActionBar from 'Layouts/Partials/ActionBar';
import LeftNav from 'Layouts/Partials/LeftNav';
import MainLayoutTutorial from 'Layouts/Partials/MainLayoutTutorial';
import MessageMenu from 'Layouts/Partials/MessageMenu';
import TutorialChecklist from 'Layouts/Partials/TutorialChecklist';
import LoadUpStats from 'Pages/Loadup/Partials/LoadUpStats';
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {toast} from 'react-toastify';
import useUser from 'Support/Hooks/useUser';


const MainLayout = ({children}) => {
  const {application: {site_name}, flash} = usePage().props;
  const user = useUser();
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    if (Array.isArray(flash)) {
      flash.forEach(({type, message}) => {
        toast(message, {
          type,
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      });
    }
  }, [JSON.stringify(flash)]);

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100 font-sans">
      <MainLayoutTutorial/>
      <Helmet titleTemplate={`%s | ${site_name}`}/>

      <LeftNav mobileOpen={mobileOpen} setMobileOpen={setMobileOpen}/>

      <div className="relative flex flex-col w-0 flex-1 overflow-hidden">
        <ActionBar setMobileOpen={setMobileOpen}/>
        <main className="flex-1 flex flex-col relative overflow-y-auto focus:outline-none" tabIndex="0" scroll-region="true">
          <div id="imageHeader" className="pt-6 empty:hidden bg-center bg-cover" style={{
            backgroundAttachment: 'fixed',
            backgroundImage: `url(${HeaderBG})`,
          }}/>
          {user.isRole('load_up_user') && (
            <LoadUpStats/>
          )}
          {children}
        </main>
        <MessageMenu/>
      </div>
      <TutorialChecklist id="tutorialChecklist"/>
    </div>
  );
};

export default MainLayout;
