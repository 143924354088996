import {Inertia} from '@inertiajs/inertia';
import {usePage} from '@inertiajs/inertia-react';
import {mdiLightbulbOn} from '@mdi/js';
import {Icon} from '@mdi/react';
import cls from 'Support/cls';
import React from 'react';
import useUser from 'Support/Hooks/useUser';
import ReactSwitch from 'Components/ReactSwitch';
import route from 'ziggy-js';

const TutorialSwitch = ({small}) => {
  const user = useUser();

  if (!usePage().props.application?.tutorial) return null;

  const onChange = (value) => {
    Inertia.patch(route('users.toggle-tutorial'), {tutorial_active: value});
  };

  return (
    <div className={cls('flex items-center gap-2 p-5 bg-gray-900', small ? 'flex-col justify-center' : 'justify-between')}>
      <div className="flex items-center gap-2">
        <Icon path={mdiLightbulbOn} className="w-6 text-white"/>
        {!small && <span className="text-sm text-white font-semibold">Show Tutorials</span>}
      </div>
      <ReactSwitch onChange={onChange} value={user?.tutorial_active}/>
    </div>
  );
};

export default TutorialSwitch;
