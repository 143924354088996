import {Link} from '@inertiajs/inertia-react';
import {Icon} from '@mdi/react';
import cls from 'Support/cls';
import React from 'react';

const LeftNavItem = ({active = false, href = '#', title = '', collapsed, icon}) => (
  <Link
    href={href}
    title={title}
    className={cls(
      active ? 'bg-gray-900 text-white' : 'hover:bg-gray-700 text-gray-300 hover:text-white focus:text-white',
      'group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md focus:outline-none focus:bg-gray-700',
      'transition ease-in-out duration-150',
    )}
  >
    {icon && <Icon path={icon} size={1} className="mr-2 text-gray-500"/>}
    <span className={`${collapsed ? 'hidden' : 'display-inline'}`}>
      {title}
    </span>
  </Link>
);

export default LeftNavItem;
