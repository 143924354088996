import {usePage} from '@inertiajs/inertia-react';
import mapValues from 'lodash.mapvalues';
import React, {useContext, useMemo} from 'react';
import FormErrorContext from 'Support/Contexts/FormErrorContext';

const useInertiaForm = defaultValues => {
  const {submittedValues: serverValues} = usePage().props;
  const {errors: initialErrors} = useContext(FormErrorContext);

  const initialValues = useMemo(
    () => ({...defaultValues, ...serverValues}),
    [defaultValues, serverValues],
  );

  const initialTouched = useMemo(
    () => mapValues(initialErrors, () => true),
    [initialErrors],
  );

  return {
    initialValues,
    initialErrors,
    initialTouched,
    enableReinitialize: true,
  };
};

export default useInertiaForm;