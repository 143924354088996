import cls from 'Support/cls';
import React from 'react';

const SecondaryButton = ({as = 'button', ...props}) => {
  const {children, className = '', innerClassName = 'text-gray-700 hover:text-gray-500', ...otherProps} = props;
  const ButtonTag = as;

  return (
    <span className={cls(
      'flex w-full rounded-md shadow-sm sm:w-auto',
      className)}>
      <ButtonTag
        className={cls(
          'inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium',
          'shadow-sm focus:outline-none focus:border-blue-300 focus:ring-blue transition ease-in-out',
          'disabled:opacity-50 disabled:cursor-not-allowed duration-150 sm:text-sm sm:leading-5',
          innerClassName,
        )}
        {...otherProps}>
        {children}
      </ButtonTag>
    </span>
  );
};

export default SecondaryButton;
