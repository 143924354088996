import cls from 'Support/cls';
import {Field} from 'formik';
import React, {useContext} from 'react';
import FieldContext from 'Support/Contexts/FieldContext';

const TextField = ({type = 'text', className = '', ...props}) => {
  const {name} = useContext(FieldContext);

  if (name === '') {
    throw new Error('Text field with missing <FieldWrapper /> or empty name attribute on FieldWrapper component');
  }

  return (
    <Field className={cls('form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-md shadow-sm', className)}
           type={type} name={name} {...props}/>
  );
};

export default TextField;
