import clx from 'classnames';
import React from 'react';

const PrimaryButton = ({children, className = '', as = 'button', ...otherProps}) => {
  const ElementTag = as;

  return (
    <span className={clx(
      'flex w-full rounded-md shadow-sm sm:w-auto',
      className)}>
      <ElementTag
        className={clx(
          'inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-orange-500 text-base leading-6 font-medium',
          'text-white shadow-sm hover:bg-orange-400 focus:outline-none focus:border-orange-600 focus:ring-orange transition ease-in-out',
          'disabled:opacity-50 disabled:cursor-not-allowed duration-150 sm:text-sm sm:leading-5')}
        {...otherProps}>
        {children}
      </ElementTag>
    </span>
  );
};

export default PrimaryButton;
