import {compareDesc, parseISO} from 'date-fns';
import sanitizeHtml from 'Layouts/Partials/NotificationsMenu/sanitizeHtml';

const generateNotificationLink = notification => {
  if (!notification.link) return;
  const link = new URL(notification.link);
  link.searchParams.append('notif_id', notification.id);
  if (link.protocol === 'http:') link.protocol = 'https:';
  return link.toString();
};

const transformAndSortNotifications = notifications => {
  const transformed = [...notifications].map(notification =>
    ({
      ...notification,
      link: generateNotificationLink(notification),
      created_at: parseISO(notification.created_at),
      is_read: !!notification.read_at,
      html_content: sanitizeHtml(notification.html_content),
    }));

  return transformed.sort((a, b) => compareDesc(a.created_at, b.created_at));
};

export default transformAndSortNotifications;