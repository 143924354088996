import {Transition} from '@headlessui/react';
import {Inertia} from '@inertiajs/inertia';
import {usePage} from '@inertiajs/inertia-react';
import {mdiAlert, mdiChevronLeft, mdiClose, mdiLoading, mdiLock, mdiMessage, mdiPlus, mdiRefresh} from '@mdi/js';
import {Icon} from '@mdi/react';
import Tippy from '@tippyjs/react';
import SecondaryButton from 'Components/Buttons/SecondaryButton';
import Message from 'Components/Messages/Message';
import ThreadIcon from 'Components/Messages/ThreadIcon';
import ThreadPreview from 'Components/Messages/ThreadPreview';
import AddMessageThreadForm from 'Forms/Messages/AddMessageThreadForm';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import axios from 'Support/axios';
import useUser from 'Support/Hooks/useUser';
import route from 'ziggy-js';

const Thread = ({threadId, onBack, refreshThreads, portalRef}) => {
  const [thread, setThread] = useState(null);
  const [editing, setEditing] = useState(false);
  const [{messages, hasMore}, setMessageData] = useState({messages: [], hasMore: false});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [timeToRefresh, setTimeToRefresh] = useState(60);
  const [showUsers, setShowUsers] = useState(false);
  const messageContainer = useRef();
  const user = useUser();

  useEffect(() => {
    if (threadId) {
      setLoading(true);
      axios.get(route('message-threads.show', threadId)).then(({data}) => {
        setThread(data?.thread);
        setMessageData({messages: data?.messages?.data?.reverse(), hasMore: data?.messages?.current_page < data?.messages?.last_page});
      }).finally(() => setLoading(false));
    }
  }, [threadId]);

  const updateName = useCallback((name) => {
    if (!!thread && name && name !== thread?.name) {
      return Inertia.put(route('message-threads.update', thread?.id), {name}, {
        onSuccess: () => setThread(thread => ({...thread, name})),
        onFinish: () => setEditing(false),
      });
    }
    setEditing(false);
  }, [thread]);

  const refreshMessages = useCallback(() => {
    if (threadId) {
      setLoading(true);
      axios.get(route('message-threads.messages', {
        messageThread: threadId,
        afterMessage: messages[messages?.length - 1]?.id,
        noPaginate: 1,
      })).then(({data}) => {
        setMessageData(prevData => ({
          ...prevData,
          messages: [...prevData.messages, ...data],
        }));
        setTimeToRefresh(60);
      }).finally(() => {
        setLoading(false);
        refreshThreads();
      });
    }
  }, [threadId, messages, refreshThreads]);

  const fetchPreviousMessages = useCallback(() => {
    if (threadId) {
      setLoading(true);
      axios.get(route('message-threads.messages', {messageThread: threadId, beforeMessage: messages[0]?.id})).then(({data}) => {
        setMessageData(prevData => ({
          messages: [...[...data?.data].reverse(), ...prevData.messages],
          hasMore: data?.current_page < data?.last_page,
        }));
      }).finally(() => setLoading(false));
    }
  }, [threadId, messages]);

  const sendMessage = useCallback(() => {
    if (threadId && message) {
      setLoading(true);
      axios.post(route('messages.send', threadId), {body: message}).then(() => {
        refreshMessages();
        setMessage('');
      }).finally(() => setLoading(false));
    }
  }, [threadId, message]);

  const scrollToBottom = useCallback(() => {
    if (messageContainer.current) {
      messageContainer.current.scrollTo({
        top: messageContainer.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages[messages?.length - 1], scrollToBottom]);

  useEffect(() => {
    if (threadId) {
      const tick = setTimeout(() => {
        setTimeToRefresh(prevTime => prevTime - 1);
      }, 1000);

      if (timeToRefresh <= 0) {
        refreshMessages();
        setTimeToRefresh(60);
      }

      return () => clearTimeout(tick);
    }
  }, [threadId, timeToRefresh]);

  return (
    <>
      <div className="flex flex-col h-full gap-2 p-4">
        <div className="flex items-center gap-2 pb-4 border-b">
          <button type="button" onClick={onBack} className="p-2 text-gray-400 bg-gray-100 hover:bg-gray-200 rounded-full">
            <Icon path={mdiChevronLeft} className="w-6"/>
          </button>
          {thread &&
            <>
              <div onMouseOver={() => setShowUsers(true)} onMouseLeave={() => setShowUsers(false)}>
                <ThreadIcon thread={thread}/>
              </div>
              {editing ?
                <input
                  autoFocus
                  defaultValue={thread?.name}
                  onBlur={e => updateName(e.target.value)}
                  onKeyDown={e => e.key === 'Enter' && updateName(e.target.value)}
                  className="grow text-lg ring-offset-2 focus:ring-2 focus:outline-none bg-gray-50 rounded"
                />
                :
                <div className="text-lg truncate" onClick={() => setEditing(true)}>{thread?.name}</div>
              }
            </>
          }
        </div>
        <div ref={messageContainer} className="relative grow overflow-overlay scrollbar-overlay -mx-3 px-3">
          <div className="flex flex-col justify-end items-start min-h-full">
            {hasMore &&
              <button
                type="button"
                onClick={fetchPreviousMessages}
                className="self-stretch p-2 text-center text-white bg-blue-500 hover:bg-opacity-75 disabled:bg-gray-500 rounded-full"
                disabled={loading}
              >
                Load Previous Messages
              </button>
            }
            {messages?.length > 0 ?
              messages?.map((message) => (
                <Message key={message.id} {...message} isCurrentUser={message?.user_id === user?.id}/>
              ))
              :
              <div className="grow self-center text-center text-gray-400">No Messages</div>
            }
          </div>
          {loading &&
            <div className="absolute inset-0 flex items-center justify-center">
              <Icon path={mdiLoading} spin={1} className="p-2 text-white bg-blue-500 w-12 rounded-full"/>
            </div>
          }
        </div>
        <div className="flex flex-col pt-4 gap-2 border-t">
          <div className="flex items-center justify-between gap-2">
            <div className="text-gray-700">Refreshing in {timeToRefresh} seconds</div>
            <button type="button" onClick={refreshMessages} disabled={loading} className="p-1 bg-blue-500 hover:bg-opacity-75 rounded-full">
              <Icon path={loading ? mdiLoading : mdiRefresh} spin={!!loading} className="w-6 text-white"/>
            </button>
          </div>
          <div className="flex-grow relative">
                 <textarea
                   onKeyDown={(e) => {
                     if (e.key === 'Enter' && !e.shiftKey) {
                       e.preventDefault();
                       sendMessage();
                     }
                   }}
                   disabled={thread?.locked}
                   value={message}
                   onChange={(e) => setMessage(e.target.value)}
                   className="w-full p-2 resize-none border rounded-lg disabled:bg-gray-100"
                 />
            {thread?.locked &&
              <div className="absolute inset-0 flex flex-col items-center justify-center text-gray-400">
                <Icon path={mdiLock} className="w-5" />
                <span>This thread is Locked</span>
              </div>
            }
          </div>
          <button
            type="button"
            onClick={sendMessage}
            className="self-end p-2 text-white bg-blue-500 disabled:bg-gray-500 hover:bg-opacity-75 rounded-lg"
            disabled={loading || !message}
          >
            Send
          </button>
        </div>
      </div>
      {portalRef?.current && createPortal(
        <Transition
          show={showUsers}
          enter="transform-gpu duration-500 ease-in-out"
          enterFrom="translate-x-full opacity-0"
          enterTo="translate-x-0 opacity-100"
          leave="transform-gpu duration-500 ease-in-out"
          leaveFrom="translate-x-0 opacity-100"
          leaveTo="translate-x-full opacity-0"
          className="flex flex-col bg-white min-w-fit max-h-full min-h-0 rounded-lg px-2 shadow-lg overflow-overlay scrollbar-overlay pb-2"
        >
          <h2 className="p-3 font-semibold text-lg border-b mb-2">Users</h2>
          {[user, ...thread?.users || []]?.map((user) => (
            <div key={user.id} className="flex items-center justify-between p-2">
              <div className="flex items-center gap-2">
                <img src={user?.avatar} alt={user?.name} className="w-8 h-8 rounded-full"/>
                <div className="text-lg">{user?.name}</div>
              </div>
            </div>
          ))}
        </Transition>
        , portalRef?.current)}
    </>
  );
};

const MessagePanel = ({open, onClose, erroredAt, threads, onUpdate}) => {
  const [creating, setCreating] = useState(false);
  const [thread, setThread] = useState(null);
  const portalRef = useRef(null);

  const refreshThreads = useMemo(() => typeof onUpdate === 'function' ? onUpdate : () => {}, [onUpdate]);

  const changeThread = useCallback((thread) => {
    setThread(thread);
    if (thread) {
      refreshThreads();
    }
  }, [refreshThreads]);

  return (
    <Transition
      show={open}
      enter="duration-300"
      enterFrom="bg-opacity-0"
      enterTo="bg-opacity-50"
      leave="duration-300"
      leaveFrom="bg-opacity-50"
      leaveTo="bg-opacity-0"
      className="fixed inset-0 z-50 overflow-hidden bg-black"
      onClick={onClose}
    >
      <Transition.Child
        as="div"
        enter="transform-gpu duration-500 ease-in-out"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transform-gpu duration-500 ease-in-out"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
        className="fixed inset-y-0 right-0 p-5 flex items-start h-full gap-5 z-50"
      >
        <div ref={portalRef} className="relative z-0 h-full"/>
        <div className="relative flex flex-col w-96 h-full bg-white rounded-xl shadow-lg overflow-hidden border-2 border-white"
             onClick={(e) => e.stopPropagation()}>
          <div className="flex items-center justify-between p-3 bg-orange-500 rounded-b-xl">
            <h1 className="text-lg text-white font-semibold">Messages</h1>
            <button type="button" onClick={onClose} className="p-1 text-white duration-100 hover:bg-orange-400 rounded-full">
              <Icon path={mdiClose} className="w-6"/>
            </button>
          </div>
          <div className="relative grow overflow-x-hidden">
            <Transition
              as="div"
              show={!thread}
              className="absolute inset-0 flex flex-col gap-2 p-4 overflow-y-auto"
              enter="duration-300"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="duration-300"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              {erroredAt ?
                <div className="flex items-center justify-center gap-2">
                  <Icon path={mdiAlert} className="w-10 text-amber-500"/>
                  <div className="flex flex-col gap-1">
                    <div className="text-lg">Unable to load messages</div>
                    <div className="text-sm">Please check your internet connection</div>
                    <div className="text-sm">If this problem persists please contact an administrator</div>
                  </div>
                </div>
                :
                <>
                  <div className="flex flex-col gap-2">
                    <SecondaryButton type="button" innerClassName="flex items-center gap-2 text-gray-600" onClick={() => setCreating(true)}>
                      <Icon path={mdiPlus} className="w-5"/>
                      <span>Create Thread</span>
                    </SecondaryButton>
                    <Transition
                      show={creating}
                      enter="duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      className="bg-gray-100 p-2 mb-5 rounded-lg"
                    >
                      <AddMessageThreadForm onClose={() => setCreating(false)}/>
                    </Transition>
                  </div>
                  {threads?.length ? threads.map(thread => (
                    <ThreadPreview key={thread?.id} thread={thread} onClick={() => changeThread(thread.id)}/>
                  )) : (
                    <div className="flex items-center justify-center p-4 bg-gray-100 text-gray-400 text-lg rounded-lg">
                      No Threads
                    </div>
                  )}
                </>
              }
            </Transition>

            <Transition
              as="div"
              show={!!thread}
              className="absolute inset-0"
              enter="duration-300"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="duration-300"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Thread threadId={thread} onBack={() => setThread(null)} refreshThreads={refreshThreads} portalRef={portalRef}/>
            </Transition>
          </div>
        </div>
      </Transition.Child>
    </Transition>
  );
};
const MessageMenu = () => {
  const [open, setOpen] = useState(false);
  const [threads, setThreads] = useState([]);
  const [erroredAt, setErroredAt] = useState(null);

  const fetchThreads = useCallback(() => {
    axios.get(route('message-threads.index', {
      withLocked: true,
    })).then(({data}) => {
      setThreads(data);
      setErroredAt(null);
    }).catch(() => {
      setErroredAt(new Date());
    });
  }, [setThreads]);

  useEffect(() => {
    const refresh = setTimeout(fetchThreads, 60000);

    return () => clearTimeout(refresh);
  }, [threads, erroredAt]);

  useEffect(() => {
    fetchThreads();
  }, [usePage()]);

  const {hasUnread, hasUnreadPing} = useMemo(() => ({
    hasUnread: threads.some(thread => new Date(thread?.last_message_at) > new Date(thread?.current_thread_user?.last_read_at)),
    hasUnreadPing: threads?.some(thread => new Date(thread?.last_ping_message?.created_at) > new Date(thread?.current_thread_user?.last_read_at)),
  }), [threads]);

  useEffect(() => {
    const onKeydown = (e) => {
      setOpen(open => {
        if (open && e.key === 'Escape') {
          return false;
        }

        if (e.key === 'm' && e.ctrlKey) {
          return !open;
        }

        return open;
      });
    };

    window.addEventListener('keydown', onKeydown);
    return () => window.removeEventListener('keydown', onKeydown);
  }, []);

  return (
    <>
      <Tippy content="Messages" placement="left">
        <button type="button" onClick={() => setOpen(true)}
                className="block fixed bottom-5 right-5 bg-orange-500 p-3 text-white rounded-full shadow-md hover:bg-orange-400 duration-100">
          <Icon path={mdiMessage} className="w-6 z-50"/>
          {hasUnread && <div className="absolute z-10 top-0 right-0 w-4 h-4 bg-blue-500 border border-white rounded-full">
            <div className="w-full h-full rounded-full bg-blue-500 animate-ping"/>
          </div>}
        </button>
      </Tippy>
      <MessagePanel
        open={open}
        onClose={() => setOpen(false)}
        threads={threads}
        erroredAt={erroredAt}
        onUpdate={fetchThreads}
      />
    </>
  );
};

export default MessageMenu;