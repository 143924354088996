import {Popover} from '@headlessui/react';
import {Inertia} from '@inertiajs/inertia';
import {Link, usePage} from '@inertiajs/inertia-react';
import {mdiAlert, mdiGhost, mdiGhostOutline, mdiQrcode, mdiQrcodeScan, mdiTarget} from '@mdi/js';
import {Icon} from '@mdi/react';
import Tippy from '@tippyjs/react';
import cls from 'Support/cls';
import ScanModal from 'Forms/QrCodes/ScanModal';
import ChangeTargetsModal from 'Forms/Settings/ChangeTargetsModal';
import HeaderBG from 'Images/galvanised_header_bg.jpg';
import AccountMenu from 'Layouts/Partials/AccountMenu';
import NotificationsMenu from 'Layouts/Partials/NotificationsMenu';
import React from 'react';
import {useModal} from 'react-modal-hook';
import useUser from 'Support/Hooks/useUser';
import route from 'ziggy-js';

const qrScanModal = onSubmit => {
  const [showModal, hideModal] = useModal(() => (
    <ScanModal hideModal={hideModal} onSubmit={onSubmit}/>
  ), []);

  return showModal;
};
const changeTargetsModal = (initialValues) => {
  const [showModal, hideModal] = useModal(() => (
    <ChangeTargetsModal hideModal={hideModal} initialValues={initialValues}/>
  ), [initialValues]);

  return showModal;
};

const ActionBar = ({setMobileOpen}) => {
  const page = usePage();
  const {is_ghosting, is_role_ghosting, role} = useUser();

  const onScanQrCode = data => {
    Inertia.get(route('qrcode.read', data.id).toString());
  };

  const showQrScanModal = qrScanModal(onScanQrCode);
  const showChangeTargetsModal = changeTargetsModal(usePage().props.application.settings);

  return (
    <>
      {/*QR fab button for small screens*/}
      <button onClick={showQrScanModal}
              className="md:hidden flex items-center justify-center z-10 p-0 p-4 w-14 h-14 text-white fixed bottom-0 right-0 mr-8 mb-8 bg-orange-500 rounded-full hover:bg-orange-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none">
        <Icon path={mdiQrcodeScan} size={0.9} color="white"/>
      </button>

      <div
        className="relative z-10 flex-shrink-0 flex h-16 bg-gradient-to-b from-black to-transparent bg-center bg-cover"
        id="actionBar"
        style={{
          backgroundAttachment: 'fixed',
          backgroundImage: `url(${HeaderBG})`,
        }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-black to-transparent -z-10"/>
        <button
          onClick={() => setMobileOpen(true)}
          id="mobile-nav-open"
          className="px-4 border-r border-gray-200 text-white focus:outline-none focus:bg-white focus:text-black lg:hidden"
          aria-label="Open sidebar">
          <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7"/>
          </svg>
        </button>
        <div className="flex-1 px-4 flex justify-between">
          <div className="flex items-stretch gap-5">
            {page.props.application.environment.toLowerCase() !== 'production' &&
              <div className="flex items-center gap-2 my-2 px-4 bg-orange-500 text-sm md:text-2xl text-white font-bold rounded-lg shadow">
                <Icon path={mdiAlert} className="w-6 md:w-8"/>
                <span className="whitespace-nowrap">GHB {usePage().props.application.environment} site</span>
              </div>
            }
            {!!((useUser().isRole('admin', 'tracking_management_user', 'developer') || is_role_ghosting) && page.props.application?.user_roles) &&
              <Popover className="relative self-center">
                {({open}) =>
                  <>
                    <Popover.Button
                      className={cls('flex items-center gap-2 px-3 py-2 rounded duration-150 outline-none focus:outline-none', open ? 'bg-indigo-500 text-white' : is_role_ghosting ? 'bg-indigo-100 text-indigo-500' : 'bg-white hover:bg-gray-100')}>
                      <Icon path={mdiGhost} className="w-6"/>
                      <span className="text-sm md:text-base">{!is_role_ghosting || open ? 'Ghost Role' : <>Ghosting as {role?.label}</>}</span>
                    </Popover.Button>
                    <Popover.Panel
                      className="absolute z-10 -bottom-2 min-w-max translate-y-full grid grid-cols-3 gap-2 auto-rows-fr font-semibold bg-white p-3 rounded-md shadow-lg">
                      {is_role_ghosting &&
                        <>
                          <div className="text-center col-span-full self-center text-lg">Currently Ghosting as <span
                            className="text-indigo-600">{role?.label}</span></div>
                          <button type="button" onClick={() => Inertia.get(route('ghost.role.stop'))}
                                  className="col-span-full px-4 py-2 bg-red-500 text-white hover:bg-red-400 shadow-sm border rounded whitespace-nowrap duration-100">Stop
                            Ghosting
                          </button>
                        </>
                      }
                      {page.props.application?.user_roles?.map(({value, label}) => (
                        <Link as="button" href={route('ghost.role.start', value)} type="button" key={value}
                              className="px-4 py-2 bg-white shadow-sm border rounded whitespace-nowrap duration-100 hover:bg-gray-50">
                          {label}
                        </Link>
                      ))}
                    </Popover.Panel>
                  </>
                }
              </Popover>
            }
          </div>
          <div className="flex-1 flex"/>
          <div className="ml-4 flex items-center space-x-4 md:ml-6">
            {/*scan qr code button*/}
              {useUser().isRole('admin', 'developer', 'tracking_management_user') &&

              <button type="button" onClick={showChangeTargetsModal}
                      className="flex items-center gap-1 px-2 md:px-4 py-2 text-sm text-indigo-500 hover:text-indigo-600 bg-indigo-100 rounded duration-150 outline-none ring-none focus:outline-none focus:ring-none">
                <Icon path={mdiTarget} size={1}/>
                <span className="hidden md:inline">Set Targets</span>
              </button>
            }
            <button type="button" onClick={showQrScanModal}
                    className="hidden md:flex md:flex-shrink-0 items-center px-4 pl-2 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-orange-500 hover:bg-orange-500 focus:outline-none focus:border-orange-600 focus:ring-orange active:bg-orange-500 transition ease-in-out duration-150">
              <Icon path={mdiQrcode} size={0.8} color="white" className="mr-2"/>
              Scan QR
            </button>
            <NotificationsMenu/>
            {is_ghosting && (
              <Tippy content="You are ghosting">
                <Icon path={mdiGhostOutline} size={1} className="text-gray-400"/>
              </Tippy>
            )}
            <AccountMenu/>

            {/*Profile dropdown*/}
            <div className="ml-3 relative">
              {/*Profile dropdown panel, show/hide based on dropdown state.*/}

              {/*Entering: "transition ease-out duration-100"*/}
              {/*  From: "transform opacity-0 scale-95"*/}
              {/*  To: "transform opacity-100 scale-100"*/}
              {/*Leaving: "transition ease-in duration-75"*/}
              {/*  From: "transform opacity-100 scale-100"*/}
              {/*  To: "transform opacity-0 scale-95"*/}
              <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg hidden">
                <div className="py-1 rounded-md bg-white ring-1 ring-black ring-opacity-5" role="menu" aria-orientation="vertical"
                     aria-labelledby="user-menu">
                  <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150" role="menuitem">Your
                    Profile</a>

                  <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150"
                     role="menuitem">Settings</a>

                  <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150" role="menuitem">Sign
                    out</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActionBar;
