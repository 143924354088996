import React, {createContext, useEffect, useState} from 'react';
import {usePage} from "@inertiajs/inertia-react";

export const FormErrorContext = createContext({});

export const FormErrorContextProvider = ({children}) => {
  const [errors, setErrors] = useState({});
  const {props: inertiaProps} = usePage();

  useEffect(() => {
    setErrors(inertiaProps.errors);
  }, [inertiaProps.errors]);

  return (
    <FormErrorContext.Provider value={{errors, setErrors}}>
      {children}
    </FormErrorContext.Provider>
  );
}

export default FormErrorContext;