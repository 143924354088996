import SimpleFieldWrapper from 'Components/Forms/SimpleFieldWrapper';
import TextField from 'Components/Forms/TextField';
import {useField} from 'formik';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import Select from 'react-select';
import axios from 'Support/axios';
import FieldContext from 'Support/Contexts/FieldContext';
import * as Yup from 'yup';
import route from 'ziggy-js';

export const validationSchema = Yup.object().shape({
  users: Yup.array().of(Yup.number()).min(1).required(),
});

const UserOption = ({innerProps, data}) => (
  <div className="p-2 hover:bg-gray-100 cursor-pointer" {...innerProps}>
    <div className="flex items-center gap-2">
      <img src={data.avatar} alt={`${data.name}'s Avatar`} className="w-10 rounded-full"/>
      <div>{data.name}</div>
    </div>
  </div>
);
const UserSelectField = () => {
  const {name} = useContext(FieldContext);
  const [{value}, _, {setValue}] = useField(name);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const controller = new AbortController();

    setLoading(true);
    axios.get(route('users.search', {withoutCurrentUser: 1}), {
      signal: controller.signal,
    }).then(({data}) => {
      setUsers(data);
    }).finally(() => {
      setLoading(false);
    });

    return () => controller.abort();
  }, []);

  const selected = useMemo(() => value ? users?.filter(user => value?.includes(user?.id)) : [], [users, value]);

  return (
    <Select
      isLoading={loading}
      isMulti
      isClearable
      components={{
        Option: UserOption,
      }}
      value={selected}
      onChange={options => setValue(options.map(option => option.id))}
      options={users}
      getOptionLabel={option => option.name}
      getOptionValue={option => option.id}
    />
  );
};

export const FormFields = () => (
  <table>
    <tbody>
    <tr>
      <th className="font-normal pr-2">Name:</th>
      <td className="py-2">
        <SimpleFieldWrapper name="name">
          <TextField/>
        </SimpleFieldWrapper>
      </td>
    </tr>

    <tr>
      <th className="font-normal pr-2">Users:</th>
      <td className="py-2">
        <SimpleFieldWrapper name="users">
          <UserSelectField/>
        </SimpleFieldWrapper>
      </td>
    </tr>
    </tbody>
  </table>
);