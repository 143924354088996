import {Transition} from '@headlessui/react';
import React from 'react';

const SlideTransition = ({children, show, from = 'top'}) => {
  return (
    <Transition
      show={show}
      enter={`transition ease-out duration-150 origin-${from}`}
      enterFrom={`transform opacity-0 scale-${['left', 'right'].includes(from) ? 'x' : 'y'}-0`}
      enterTo={`transform opacity-100 scale-${['left', 'right'].includes(from) ? 'x' : 'y'}-100`}
      leave={`transition ease-in duration-150 origin-${from}`}
      leaveFrom={`transform opacity-100 scale-${['left', 'right'].includes(from) ? 'x' : 'y'}-100`}
      leaveTo={`transform opacity-0 scale-${['left', 'right'].includes(from) ? 'x' : 'y'}-0`}
    >
      {children}
    </Transition>
  );
};

export default SlideTransition;