import React, {Suspense} from 'react';
import ReactModal from 'react-modal';
import ModalContext from 'Support/Contexts/ModalContext';

const Modal = ({children, onClose, contentLabel, closeOnOverlay = true, ...props}) => (
  <ReactModal isOpen {...props}
              style={{overlay: {}, content: {}}}

              onRequestClose={onClose}

              shouldCloseOnOverlayClick={closeOnOverlay}

              contentLabel={contentLabel}

              portalClassName={'modal-container'}

              overlayClassName={{
                base: 'fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center overflow-y-auto',
                afterOpen: '',
                beforeClose: '',
              }}

              className={{
                base: 'bg-white w-full mx-4 md:w-7/12 lg:w-5/12 xl:w-4/12 shadow-xl rounded-lg outline-none max-h-screen-9/10',
                afterOpen: '',
                beforeClose: '',
              }}

              bodyOpenClassName={'modal-open'}
  >
    <ModalContext.Provider value={{isModal: true}}>
      <Suspense fallback={<div>Loading Modal</div>}>
        {children}
      </Suspense>
    </ModalContext.Provider>
  </ReactModal>
);

export default Modal;