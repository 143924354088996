import Tutorial from 'Components/Tutorial/Tutorial';
import React from 'react';
import TutorialMenuButtonImage from 'Images/tutorial_menu_button.png'

const tutorialSteps = [
  {
    title: 'Welcome to GHB',
    intro: 'Welcome to the Galvanising HB web management application',
  },
  {
    intro: React.createElement(() => (
      <>
        <div className="mb-2">This tour will take you through the basics of how to use this application</div>
        <div>Let's Begin</div>
      </>
    )),
  },
  {
    element: '#navigation',
    title: 'Navigation',
    intro: 'The navigation bar is how we navigate through the various different sections and pages of the app',
  },
  {
    element: '#actionBar',
    title: 'Action Bar',
    intro: 'The action bar is where notifications and actions that pertain to the currently signed in user are displayed',
  },
  {
    element: '#accountMenu',
    title: 'Account Menu',
    intro: React.createElement(() => (
      <div className="flex flex-col gap-2">
        <div>The account menu can be accessed by clicking/tapping the user avatar</div>
        <div>It provides the email address of the currently signed in user as well as access to account settings, and the ability to log out</div>
      </div>
    )),
  },
  {
    element: '#notificationMenu',
    title: 'Notification Menu',
    intro: 'The notification menu displays notifications that have been sent to the user',
  },
  {
    element: '#tutorialChecklist',
    title: 'Tutorial Checklist',
    intro: 'If at any point during the tutorial you are lost or unsure what to do next, refer to the tutorial checklist',
  },
  {
    title: 'Tutorial Resources',
    intro: <div className="flex flex-col gap-2 w-96">
      <div>Some pages have additional learning resources that may be useful</div>
      <div>If a page has additional resources this menu button will appear in the top left corner of the page</div>
      <div className="flex justify-center">
        <img src={TutorialMenuButtonImage} className="w-24 shadow-lg" alt="Tutorial menu button"/>
      </div>
      <div>Click on this button to view a menu of additional resources available for the page</div>
    </div>,
  },
  {
    title: 'Moving on',
    intro: 'Now that you know the base layout of the application we can move on to information about the various pages of the application',
  },
];

const MainLayoutTutorial = () =>
  <Tutorial
    section="main_layout"
    steps={tutorialSteps}
    tourOptions={{
      doneLabel: 'Continue',
      hideNext: false,
    }}
  />;

export default MainLayoutTutorial;