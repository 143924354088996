import {mdiBell} from '@mdi/js';
import {Icon} from '@mdi/react';
import cls from 'Support/cls';
import ThreadIcon from 'Components/Messages/ThreadIcon';
import React, {useMemo} from 'react';

const ThreadPreview = ({thread, onClick}) => {
  const hasUnreadPing = useMemo(() => new Date(thread?.last_ping_message?.created_at) > new Date(thread?.current_thread_user?.last_read_at));

  return (
    <button type="button" onClick={onClick} className="flex items-center gap-2 p-2 duration-100 hover:bg-indigo-100 rounded-lg">
      <ThreadIcon thread={thread}/>
      <div
        className={cls('flex-1 flex flex-col min-w-0 items-start', new Date(thread?.last_message_at) > new Date(thread.current_thread_user?.last_read_at) && 'font-semibold')}>
        <div className="text-lg truncate max-w-full">{thread?.name}</div>
        <div className="text-sm truncate max-w-full">{thread?.last_message?.body}</div>
      </div>
      {hasUnreadPing &&
        <div className="relative text-orange-500">
          <Icon path={mdiBell} className="w-10"/>
          <Icon path={mdiBell} className="absolute inset-0 w-10 animate-ping"/>
        </div>
      }
    </button>
  );
};

export default ThreadPreview;
