import {usePage} from '@inertiajs/inertia-react';
import TutorialSections from 'Components/Tutorial/FlattenedTutorialSections';
import {Hints, Steps} from 'intro.js-react';
import React, {forwardRef, useMemo, useState} from 'react';
import useTutorialSectionUpdate from 'Support/Hooks/useTutorialSectionUpdate';
import useUser from 'Support/Hooks/useUser';

const defaultTourOptions = {
  exitOnOverlayClick: false,
  exitOnEsc: false,
  hidePrev: true,
  showBullets: false,
  showProgress: true,
  skipLabel: '<span class="text-sm font-medium text-gray-300">Skip</span>',
  tooltipClass: 'max-w-none'
};

const TutorialComponents = forwardRef(({
  section,
  waitForSections,
  steps,
  hints,
  enabled = true,
  initialStep = 0,
  onExit,
  onComplete,
  tourOptions = {},
  hintOptions,
  ...props
}, ref) => {
  const completedSections = useUser()?.tutorial_sections || [];
  const completeSection = useTutorialSectionUpdate(section, true);
  const [completed, setCompleted] = useState(false);

  const user = useUser();

  const canStart = useMemo(() => {
    const allowedTutorials = TutorialSections.reduce((acc, section) => !Array.isArray(section?.userRoles) || user.isRole(...section.userRoles) ? [...acc, section.id] : acc, []);
    if (!allowedTutorials.includes(section)) return false;
    return !Array.isArray(waitForSections) || waitForSections.every((section) => !allowedTutorials.includes(section) || completedSections.includes(section));
  }, [user, section, waitForSections]);

  if (!usePage().props.application?.tutorial || !user.tutorial_active) return null;

  const handleExit = () => {
    completeSection().then(() => typeof onComplete === 'function' && completed && onComplete());
    if (typeof onExit === 'function') onExit();
  };

  return (
    <>
      {!!steps?.length &&
        <Steps enabled={!completedSections.includes(section) && canStart && enabled} steps={steps} initialStep={initialStep}
               options={{...defaultTourOptions, ...tourOptions}} ref={ref} onExit={handleExit} onComplete={() => setCompleted(true)} {...props}/>
      }
      {!!hints?.length &&
        <Hints enabled={true}
               hints={hints} hintOptions={hintOptions}/>
      }
    </>
  );
});

const Tutorial = forwardRef((props, ref) => {
  if (!usePage().props.application?.tutorial || !useUser().tutorial_active) return null;
  return <TutorialComponents ref={ref} {...props}/>;
});

export default Tutorial;