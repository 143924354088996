import {Link} from '@inertiajs/inertia-react';
import {mdiBell} from '@mdi/js';
import {Icon} from '@mdi/react';
import React from 'react';

const Ping = ({ping}) => (
  <Link href={ping?.url}
        className="flex items-center gap-2 px-2 py-1 border border-primary text-gray-900 bg-white hover:bg-primary/10 duration-100 rounded shadow-sm">
    <div className="p-2 bg-orange-500 text-white rounded-full">
      <Icon path={mdiBell} className="w-7"/>
    </div>
    <div className="flex-1 p-1 text-left">
      {ping?.title && <div className="font-bold">{ping.title}</div>}
      {ping?.body && <div className="text-xs">{ping.body}</div>}
    </div>
  </Link>
);

export default Ping;