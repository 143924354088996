import PrimaryButton from 'Components/Buttons/PrimaryButton';
import SecondaryButton from 'Components/Buttons/SecondaryButton';
import Modal from 'Components/Modals/Modal';
import ModalContentWrapper from 'Components/Modals/ModalContentWrapper';
import ContentFooterWrapper from 'Components/PageLayout/ContentFooterWrapper';
import React, {useEffect, useRef, useState} from 'react';
import {QrReader} from 'react-qr-reader';
import useStateDebounced from 'Support/Hooks/useStateDebounced';

const ScanModal = ({onSubmit, hideModal}) => {
  const [debouncedScan, setScan] = useStateDebounced(null, 150);
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(0);
  const scanInput = useRef();

  const onScan = data => {
    if (data) {
      try {
        data = JSON.parse(data);
        onSubmit(data);
        hideModal();
      } catch {
        try {
          const url = new URL(data);
          if (url.hostname === window.location.hostname) window.location.href = url.href;
        } catch {
        }
      }
    }
  };

  const onError = () => {
  };

  const handleKeyPress = (event) => {
    if (event.target !== scanInput.current && event.key.toLowerCase() !== 'escape') {
      scanInput.current.focus();
    }
  };

  useEffect(() => {
    try {
      const scanJsonString = debouncedScan.toLowerCase().match(/\{[\s\S]*?\}/g);
      if (JSON.parse(scanJsonString)) {
        onScan(scanJsonString);
      }
    } catch {
    }
  }, [debouncedScan]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);

    navigator.mediaDevices.enumerateDevices().then(devices => {
      const videoDevices = devices.filter(device => device.kind === 'videoinput');
      setDevices(videoDevices);

      if (videoDevices.length === 1) {
        setSelectedDevice(0);
      } else if (videoDevices.length > 1) {
        const backFacing = videoDevices.findIndex(device => device.getCapabilities().facingMode.includes('environment'));
        setSelectedDevice(backFacing > 1 ? backFacing : 0);
      }
    })

    return () => document.removeEventListener('keydown', handleKeyPress);
  }, []);

  return (
    <Modal onClose={hideModal} contentLabel="Scan QR Code">
      <ModalContentWrapper onClose={hideModal} title="Scan QR Code">
        <QrReader
          key={selectedDevice}
          onResult={onScan}
          constraints={{
            deviceId: devices[selectedDevice]?.deviceId,
          }}
          style={{width: '100%'}}
        />
        <PrimaryButton type="button" onClick={() => setSelectedDevice((selectedDevice + 1) % devices?.length)}>
          Change Camera
        </PrimaryButton>
        <input ref={scanInput} className="fixed opacity-0" type="text" onChange={({target: {value}}) => setScan(value)}/>
      </ModalContentWrapper>

      <ContentFooterWrapper>
        <SecondaryButton type="button" onClick={hideModal}>Cancel</SecondaryButton>
        <PrimaryButton type="button" onClick={onSubmit} disabled>Go</PrimaryButton>
      </ContentFooterWrapper>
    </Modal>
  );
};

export default ScanModal;
