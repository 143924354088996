import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import React from 'react';

const Metric = ({icon, title, value, onClick, tooltip}) => (
  <Tippy disabled={!tooltip} content={tooltip}>
    <button onClick={onClick} className="bg-white overflow-hidden shadow rounded-lg px-4 py-5 sm:p-6 flex items-center text-left">
      <div className="flex-shrink-0 bg-orange-500 rounded-md p-3">
        {icon}
      </div>
      <div className="ml-5 w-0 flex-1">
        <dl>
          <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
            {title}
          </dt>
          <dd className="flex items-baseline">
            <div className="text-2xl leading-8 font-semibold text-gray-900">
              {value}
            </div>
          </dd>
        </dl>
      </div>
    </button>
  </Tippy>
);

export default Metric;