import TutorialSections from 'Components/Tutorial/TutorialSections.json';

const flattenSection = (section, depth = 0) => {
  const outSections = [{...section, depth: depth}];
  const subSections = section?.subSections?.flatMap((section) => flattenSection(section, depth + 1));
  if (!Array.isArray(subSections)) return outSections;
  return outSections.concat(subSections);
};

const FlattenedTutorialSections = TutorialSections.flatMap((section) => flattenSection(section, 0));

export default FlattenedTutorialSections;