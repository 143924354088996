import {ErrorMessage} from 'formik';
import React from 'react';
import {FieldContext} from 'Support/Contexts/FieldContext';

const SimpleFieldWrapper = ({name, children, ...props}) => (
  <div {...props}>
    <FieldContext.Provider value={{name}}>
      {children}
    </FieldContext.Provider>
    <ErrorMessage name={name} component="div" className="bg-red-500 text-white p-2 text-xs"/>
  </div>
);

export default SimpleFieldWrapper;