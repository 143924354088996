import React from 'react';
import {Formik as BaseFormik} from 'formik';
import {useMemo} from 'react';
import FormikContext from 'Support/Contexts/FormikContext';

const Formik = props => {
  const contextValue = useMemo(() => ({validationSchema: props.validationSchema}), [props.validationSchema]);

  return (
    <FormikContext.Provider value={contextValue}>
      <BaseFormik {...props}/>
    </FormikContext.Provider>
  );
};

export default Formik;