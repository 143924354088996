import {Combobox, RadioGroup, Transition} from '@headlessui/react';
import {Inertia} from '@inertiajs/inertia';
import {mdiConsole, mdiLoading, mdiMagnify} from '@mdi/js';
import {Icon} from '@mdi/react';
import cls from 'Support/cls';
import InertiaLogo from 'Images/inertia_logo.png';
import React, {useEffect, useMemo, useReducer, useRef, useState} from 'react';
import route from 'ziggy-js';

const ParameterEntry = ({name, optional, type, onChange, ...props}) => (
  <div className="flex flex-col">
      <span className="font-semibold px-1 text-sm">
        <span className="text-gray-500">{name}</span>
        {!optional && <span className="text-red-500">*</span>}
      </span>
    <input
      type="text"
      onChange={({target: {value}}) => onChange(value)}
      {...props}
      className="border-0 bg-white p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6 lg:rounded-md lg:ring-1 lg:ring-inset lg:ring-gray-300 lg:focus:ring-2 lg:focus:ring-inset lg:focus:ring-indigo-600"
    />
  </div>
);

const navOptionReducer = (state, action) => {
  switch (action.type) {
    case 'setRoute':
      return {
        ...(action.payload?.name === state?.route?.name) ? state : {
          parameters: {},
          method: action?.payload?.methods?.includes(state?.method) ? state?.method : action?.payload?.methods?.[0],
        },
        route: action.payload,
      };
    case 'setParameter':
      return {...state, parameters: {...state?.parameters, [action.payload?.name]: action?.payload?.value}};
    case 'setMethod':
      return {...state, method: action?.payload};
    case 'reset':
      return {route: null, parameters: {}, method: 'GET'};
    default:
      throw new Error('Invalid route action type');
  }
};

const responseReducer = (state, action) => {
  switch (action.type) {
    case 'startRequest':
      return {loading: true, invalidResponse: null};
    case 'endRequest':
      return {loading: false, invalidResponse: null};
    case 'invalidResponse':
      return {loading: false, invalidResponse: action.payload};
    default:
      throw new Error('Invalid response action type');
  }
};

const RouteNavigator = ({onClose, searchRef}) => {
  const [search, setSearch] = useState('');
  const onNavigate = useRef(null);
  const [{invalidResponse, loading}, dispatchRepsonseAction] = useReducer(responseReducer, {loading: false, invalidResponse: null});
  const [navigateOptions, dispatchNavigationOptionAction] = useReducer(navOptionReducer, {route: null, parameters: {}});

  const routes = useMemo(() => {
    dispatchNavigationOptionAction({type: 'reset'});
    if (!search) return [];
    return Object.entries(Ziggy?.routes)?.filter(([route]) => route.includes(search))?.slice(0, 10);
  }, [search]);

  const routeParams = useMemo(() => {
    if (!navigateOptions?.route) return [];
    return (navigateOptions?.route?.uri?.match(/(?<=\{)[^}]*(?=})/g) ?? [])?.map(param => ({
      name: param?.replace('?', ''),
      optional: param.endsWith('?'),
    }));
  }, [navigateOptions?.route]);

  const canNavigate = routeParams?.every(({name, optional}) => !!navigateOptions?.parameters?.[name] || optional);

  onNavigate.current = () => {
    dispatchRepsonseAction({type: 'startRequest'});
    Inertia.visit(route(navigateOptions?.route?.name, navigateOptions?.parameters), {
      method: navigateOptions?.method,
      onSuccess: () => {
        dispatchRepsonseAction({type: 'endRequest'});
        onClose();
      },
    });
  };

  useEffect(() => {
    if (!canNavigate) return;

    const handleKeyDown = ({key}) => {
      if (key === 'Enter') onNavigate?.current?.();
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [canNavigate]);

  useEffect(() => {
    const onInvalid = (event) => {
      event.preventDefault();
      dispatchRepsonseAction({type: 'invalidResponse', payload: event.detail?.response});
    };

    return Inertia.on('invalid', onInvalid);
  }, []);

  return (
    <div className="relative mt-5 p-5 rounded-2xl shadow-lg bg-white grow max-w-4xl pointer-events-auto isolate">
      <a href="https://inertiajs.com/" target="_blank">
        <img src={InertiaLogo} alt="Inertia Logo"
             className="absolute top-5 rounded-l-lg shadow-lg left-0 -translate-x-full w-10 duration-100 origin-right hover:scale-105"/>
      </a>
      <div className="z-10">
        <Combobox value={navigateOptions?.route} onChange={(selected) => {
          dispatchNavigationOptionAction({
            type: 'setRoute',
            payload: selected,
          });
        }} as="div" className="relative">
          <div className="relative">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <Icon path={mdiMagnify} className="h-5 w-5 text-gray-400" aria-hidden="true"/>
            </div>
            <Combobox.Input
              displayValue={(item) => item?.name}
              ref={searchRef}
              value={search}
              onChange={({target: {value}}) => setSearch(value)}
              autoComplete="off"
              autoCorrect="off"
              className="block w-full border-0 bg-white pl-10 pr-14 py-1.5 text-2xl text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6 lg:rounded-md lg:ring-1 lg:ring-inset lg:ring-gray-300 lg:focus:ring-2 lg:focus:ring-inset lg:focus:ring-indigo-600 shadow-lg"
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <kbd
                className="leading-[0.7em] block p-1.5 pt-2 font-bold text-xs border border-gray-300 shadow-sm rounded-md text-gray-500 bg-white">
                ESC
              </kbd>
            </div>
          </div>
          {!!routes?.length && (
            <Combobox.Options className="pt-5">
              {routes?.map(([routeName, routeObj]) => (
                <Combobox.Option key={routeName} value={{
                  name: routeName,
                  ...routeObj,
                }}>
                  {({selected, active, ...props}) =>
                    <div
                      className={cls('flex items-center justify-between rounded-lg px-3 py-2 cursor-default', active ? 'bg-indigo-500 text-white' : 'text-gray-800')} {...props}>
                      <span className="text-xl">{routeName}</span>
                      <span className={cls('text-sm', active ? 'text-indigo-200' : 'text-gray-500')}>{routeObj.uri}</span>
                    </div>
                  }
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </Combobox>
        {!!navigateOptions?.route &&
          <>
            {!!routeParams?.length &&
              <div className="grid grid-cols-5 items-center gap-2 mt-5 bg-gray-50 p-2 rounded-md">
                {routeParams?.map((param) => (
                  <ParameterEntry
                    key={param?.name}
                    {...param}
                    value={navigateOptions?.parameters?.[param?.name] ?? ''}
                    onChange={(value) => dispatchNavigationOptionAction({
                      type: 'setParameter',
                      payload: {
                        name: param?.name,
                        value,
                      },
                    })}
                  />
                ))}
              </div>
            }
            <div className="flex items-center justify-between gap-5 mt-4">
              <RadioGroup
                value={navigateOptions?.method}
                onChange={(value) => dispatchNavigationOptionAction({
                  type: 'setMethod',
                  payload: value,
                })}
                className="flex items-center gap-2 whitespace-nowrap"
              >
                {navigateOptions?.route?.methods?.map((method) => (
                  <RadioGroup.Option
                    key={method}
                    value={method}
                    as="button"
                    type="button"
                    className={({checked}) => cls('flex items-center gap-2 px-3 py-1 text-sm rounded-lg duration-100 select-none', checked ? 'text-white bg-indigo-500 hover:bg-indigo-600' : 'text-gray-500 bg-gray-200')}
                  >
                    {({checked}) => (
                      <>
                        <div className={cls('w-3 h-3 border-2 border-white rounded-full duration-100', checked ? 'bg-inherit' : 'bg-white')}/>
                        <span className="font-bold">{method}</span>
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </RadioGroup>
              <button
                type="button"
                onClick={onNavigate?.current}
                disabled={!canNavigate}
                className="relative flex items-center gap-5 py-1 pl-5 pr-2 rounded-lg text-white bg-gradient-to-tr from-fuchsia-500 to-indigo-500 duration-100 disabled:opacity-50 enabled:hover:scale-105 shadow-md enabled:hover:shadow-lg"
              >
                <div className={cls('contents', loading && 'invisible')}>
                  <span className="text-lg italic font-bold">GO!</span>
                  <kbd
                    className="leading-[0.7em] block p-1.5 pt-2 font-bold text-xs rounded-md text-gray-500 bg-white">
                    ENTER
                  </kbd>
                </div>
                {loading && (
                  <span className="absolute inset-0 flex items-center justify-center">
                    <Icon path={mdiLoading} className="h-6 w-6 text-white animate-spin"/>
                  </span>
                )}
              </button>
            </div>
          </>
        }
        {!!invalidResponse &&
          <div className="bg-red-200 text-red-600 rounded-lg px-3 py-2 mt-4 leading-tight">
            <div className="flex items-center justify-between">
              <span>Received an invalid Inertia response. Click the button to the right to see the response in the console.</span>
              <button type="button" className="rounded p-1 hover:bg-red-300" onClick={() => console.log(invalidResponse)}>
                <Icon path={mdiConsole} className="w-4"/>
              </button>
            </div>
            {!!invalidResponse?.headers?.['content-type'] &&
              <div className="text-xs font-semibold">Content Type: <span
                className="inline-block px-1 bg-red-300 text-red-700 rounded">{invalidResponse.headers['content-type']}</span></div>
            }
          </div>
        }
      </div>
    </div>
  );
};

const ActionOverlay = ({openKey = 'F2', backdrop = true}) => {
  const searchRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const onKeyDown = (event) => {
      if (event.key === 'Escape') {
        setIsOpen(false);
      }

      if (event.key === openKey) {
        event.preventDefault();
        setIsOpen((isOpen) => !isOpen);
      }
    };

    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, [openKey]);

  return (
    <div className="fixed inset-0 z-[999999] pointer-events-none">
      <div
        onClick={() => setIsOpen(false)}
        className={cls('absolute inset-0 duration-300 transition-opacity', backdrop && 'backdrop-blur-sm bg-black/10', isOpen ? 'pointer-events-auto' : 'opacity-0')}
      />
      <Transition
        show={isOpen}
        enter="ease-in-out duration-300"
        enterFrom="-translate-y-full"
        enterTo="translate-y-0"
        leave="ease-in-out duration-300"
        leaveFrom="translate-y-0"
        leaveTo="-translate-y-full"
        className="absolute top-0 inset-x-0 flex justify-center"
        afterEnter={() => searchRef.current?.focus()}
      >
        <RouteNavigator onClose={() => setIsOpen(false)} searchRef={searchRef}/>
      </Transition>
    </div>
  );
};

export default ActionOverlay;
