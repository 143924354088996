import PrimaryButton from 'Components/Buttons/PrimaryButton';
import SecondaryButton from 'Components/Buttons/SecondaryButton';
import Formik from 'Components/Forms/Formik';
import {Form} from 'formik';
import {FormFields, validationSchema} from 'Forms/Messages/MessageThreadForm';
import useInertiaForm from 'Support/Hooks/useInertiaForm';
import useInertiaSubmit from 'Support/Hooks/useInertiaSubmit';
import route from 'ziggy-js';

const AddMessageThreadForm = ({onClose}) => {
  const formikProps = useInertiaForm({
    name: '',
    users: [],
  });

  const submit = useInertiaSubmit({
    route: route('message-threads.store'),
    preserveScroll: true,
    preserveState: true,
    onSuccess: () => onClose(),
  });

  return (
    <Formik {...formikProps} onSubmit={submit} validationSchema={validationSchema}>
      {({dirty, isValid}) => (
        <Form className="flex flex-col">
          <FormFields/>
          <div className="grid grid-cols-2 gap-2">
            <SecondaryButton type="button" onClick={onClose}>Cancel</SecondaryButton>
            <PrimaryButton type="submit" disabled={!dirty || !isValid}>Add</PrimaryButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddMessageThreadForm;