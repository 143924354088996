import route from 'ziggy-js';
import _ from 'lodash';
import useInertiaSubmit from 'Support/Hooks/useInertiaSubmit';

const useTutorialSectionUpdate = (section, complete) => {
  const submit = useInertiaSubmit({
    route: route(`tutorial.${complete ? 'complete' : 'forget'}-sections`),
  });

  return () => submit({sections: _.castArray(section)});
};

export default useTutorialSectionUpdate;