import PrimaryButton from 'Components/Buttons/PrimaryButton';
import SecondaryButton from 'Components/Buttons/SecondaryButton';
import FieldWrapper from 'Components/Forms/FieldWrapper';
import Formik from 'Components/Forms/Formik';
import TextField from 'Components/Forms/TextField';
import Modal from 'Components/Modals/Modal';
import ModalContentWrapper from 'Components/Modals/ModalContentWrapper';
import ContentFooterWrapper from 'Components/PageLayout/ContentFooterWrapper';
import {Form} from 'formik';
import React from 'react';
import useInertiaForm from 'Support/Hooks/useInertiaForm';
import useInertiaSubmit from 'Support/Hooks/useInertiaSubmit';
import * as Yup from 'yup';
import route from 'ziggy-js';

const validationSchema = Yup.object().shape({
  dips_per_hour_target: Yup.number().min(0).required('Please provide a target'),
  kg_per_hour_target: Yup.number().min(0).required('Please provide a target'),
})

const ChangeTargetsModal = ({hideModal, initialValues}) => {
  const formikProps = useInertiaForm({
    dips_per_hour_target: initialValues?.dips_per_hour_target,
    kg_per_hour_target: initialValues?.kg_per_hour_target,
  })

  const onSubmit = useInertiaSubmit({
    route: route('settings.change'),
    onSuccess: hideModal,
  })

  return (
    <Modal contentLabel="Change Targets">
      <Formik {...formikProps} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({isValid, values}) => (
          <Form>
            <ModalContentWrapper
              onClose={hideModal}
              title="Change Targets"
              subtitle="Change the dipping targets"
            >
              <FieldWrapper name="dips_per_hour_target" label="Dips Per Hour" noBorder>
                <TextField type="number" min="0"/>
                {!!values.dips_per_hour_target && <div className="text-sm text-gray-500">Approx. <b>{Math.round(60/values?.dips_per_hour_target)} minutes</b> per dip</div>}
              </FieldWrapper>

              <FieldWrapper name="kg_per_hour_target" label="KG Per Hour">
                <TextField type="number" min="0"/>
              </FieldWrapper>
            </ModalContentWrapper>
            <ContentFooterWrapper>
              <SecondaryButton type="button" onClick={hideModal}>Cancel</SecondaryButton>
              <PrimaryButton type="submit" disabled={!isValid}>Change</PrimaryButton>
            </ContentFooterWrapper>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default ChangeTargetsModal;