import {mdiLock} from '@mdi/js';
import {Icon} from '@mdi/react';
import Tippy from '@tippyjs/react';
import React, {useMemo} from 'react';

const ThreadIcon = ({thread}) => {
  const isGroup = useMemo(() => thread?.users?.length > 1, [thread?.users]);

  return (
    <div className="relative w-12 h-12">
      {isGroup ?
        <>
          <img src={thread?.users[0]?.avatar} alt={`${thread?.users[0]?.name}'s Avatar`} className="absolute w-8 top-0 right-0 rounded-full"/>
          <img src={thread?.users[1]?.avatar} alt={`${thread?.users[1]?.name}'s Avatar`} className="absolute w-8 bottom-0 left-0 rounded-full"/>
        </>
        :
        <img src={thread?.users[0]?.avatar} alt={`${thread?.users[0]?.name}'s Avatar`} className="w-12 rounded-full"/>
      }
      {thread?.locked &&
        <Tippy content="This thread is locked because there are no active users" placement="top">
          <div className="absolute bottom-0 right-0 bg-black text-white p-1 rounded-full">
            <Icon path={mdiLock} className="w-3 h-3"/>
          </div>
        </Tippy>
      }
    </div>
  );
};

export default ThreadIcon;