import cls from 'Support/cls';
import React from 'react';

const ContentFooterWrapper = ({children, className = undefined}) => (
  <div className={cls('bg-gray-50 px-4 py-3 px-6 gap-3 flex items-center justify-end rounded-b-lg', className)}>
    {children}
  </div>
);

export default ContentFooterWrapper;
