import {Menu} from '@headlessui/react';
import {Link, usePage} from '@inertiajs/inertia-react';
import {mdiBell, mdiBellOutline, mdiEmailOpenOutline, mdiEmailOutline} from '@mdi/js';
import {Icon} from '@mdi/react';
import cls from 'Support/cls';
import MenuItemsWrapper from 'Components/MenuItemsWrapper';
import {formatDistanceToNowStrict} from 'date-fns';
import transformAndSortNotifications from 'Layouts/Partials/NotificationsMenu/transformAndSortNotifications';
import React, {useState} from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import ImageOrIcon from './NotificationsMenu/NotificationImageOrIcon';

const NoNotifications = () => {
  return (
    <div className="text-center px-4 py-8 text-gray-400 opacity-50 font-bold focus:outline-none">
      It's all quiet here 😴
    </div>
  );
};

const NotificationsMenu = ({}) => {

  const [trigger, setTriggerElement] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const {application: {notifications: stateNotifications}} = usePage().props;

  useDeepCompareEffect(() => {
    setNotifications(stateNotifications);
  }, [stateNotifications]);

  const sortedNotifications = transformAndSortNotifications(notifications);
  const numberUnreadNotifications = sortedNotifications.filter(({is_read}) => !is_read).length;

  return (
    <div className="flex items-center justify-center" id="notificationMenu">
      <div className="relative inline-block text-left">
        <Menu>
          {({open}) => (
            <>
              <Menu.Button
                ref={setTriggerElement}
                className="relative p-1 rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring focus:text-gray-500 relative"
                aria-label="Notifications" aria-haspopup="true">
                <Icon path={numberUnreadNotifications > 0 ? mdiBell : mdiBellOutline} className={cls('h-6 w-6', numberUnreadNotifications > 0 ? 'text-orange-500' : 'text-gray-400')}/>
                {numberUnreadNotifications > 0 &&
                <div className="absolute right-1 bottom-1 rounded-full w-2 h-2 bg-blue-500 rounded-full border border-white"/>
                }
              </Menu.Button>

              <MenuItemsWrapper show={open} trigger={trigger} width="w-72" padding="p-0">
                <div className="max-h-screen-3/4 overflow-auto">
                  {sortedNotifications.length === 0 && <NoNotifications/>}

                  {sortedNotifications.map(({id, link, image, type, html_content, text_content, is_read, created_at}) => (
                    <Menu.Item key={id}>
                      {({active}) => (
                        <Link href={link} className={cls({
                          'bg-gray-100': active,
                          'opacity-50': is_read,
                          'flex items-center px-4 py-3 hover:bg-gray-100 cursor-pointer focus:outline-none': true,
                        })}>
                          <ImageOrIcon image={image} type={type}/>
                          <div className="text-gray-600 text-sm mx-2">
                            <div dangerouslySetInnerHTML={{__html: html_content || text_content}}/>
                            <div className="text-xs flex justify-between">
                              <span>{formatDistanceToNowStrict(created_at, {addSuffix: true})}</span>
                              <Icon path={is_read ? mdiEmailOpenOutline : mdiEmailOutline} size={0.7} className={cls({'text-gray-400': !is_read})}/>
                            </div>
                          </div>
                        </Link>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </MenuItemsWrapper>
            </>
          )}
        </Menu>
      </div>
    </div>
  );
};

export default NotificationsMenu;
