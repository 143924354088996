import {usePage} from '@inertiajs/inertia-react';
import {mdiFolderOutline, mdiHook, mdiJumpRope, mdiWeight} from '@mdi/js';
import {Icon} from '@mdi/react';
import Metric from 'Components/Metric';
import React from 'react';
import {formatNumber} from 'Support/valueFormatters';

const LoadUpStats = () => {
  const {application: {jigsCompletedToday, ropesCompletedToday, weightLoadedUpToday, weightToDoToday, jobsToDoToday}} = usePage().props;

  return (
    <div>
      <div className="mt-8 max-w-7xl mx-auto px-4 sm:px-6 md:px-8 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 mt-4">
        <Metric icon={<Icon path={mdiFolderOutline} size={1} color="white"/>} title="Jobs to do Today" value={formatNumber(jobsToDoToday, 0)}/>
        <Metric icon={<Icon path={mdiWeight} size={1} color="white"/>} title="Weight to do Today" value={`${formatNumber(weightToDoToday, 0)}kg`}/>
        <Metric icon={<Icon path={mdiHook} size={1} color="white"/>} title="Jigs Completed Today" value={formatNumber(jigsCompletedToday, 0)}/>
        <Metric icon={<Icon path={mdiJumpRope} size={1} color="white"/>} title="Ropes Completed Today" value={formatNumber(ropesCompletedToday, 0)}/>
        <Metric icon={<Icon path={mdiWeight} size={1} color="white"/>} title="Weight Completed Today"
                value={`${formatNumber(weightLoadedUpToday, 0)}kg`}/>
      </div>
    </div>
  );
};

export default LoadUpStats;